import { Button, Divider, Flex, Grid, Group, Input, Text, useMantineTheme } from '@mantine/core';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElementComponent } from '../../FormElementComponent';
import { FormElement } from '../../models/FormElement';
import { isPresent } from 'utilitype';
import { runnerStyles } from './runnerStyles';
import React, { useEffect, useState } from 'react';
import { repeaterElementPath } from './repeaterElementPath';
import { RepeaterRunnerModel } from './repeaterRunnerModel';
import { ViewerRepeater } from './ViewerRepeater';
import { FormElementType } from '../../models/FormElementType';
import { FileDelete } from '../../models/FileDelete';
import { FileUpload } from '../../models/FileUpload';
import { v4 as uuidv4 } from 'uuid';
import { ErrorMessages } from '../../../../utils/errorMessages';
import { t } from 'ttag';
import LanguageHandler from '../../../../utils/languageHandler';
import { LanguageCode } from '../../../../models/LanguageCode';
import { FormElementRepeater } from '../../models/FormElementRepeater';
import Formatter from '../../../../utils/formatter';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { TextBoxInputType } from '../TypedTextBox/TextBoxInputType';
import { useMediaQuery } from '@mantine/hooks';
import { FormLabel } from '../Common/FormLabel';

export type RepeaterProps = {
  element: FormElement & FormElementRepeater;
} & FormElementComponentProps;

export const Repeater: React.FC<RepeaterProps> = ({
  number,
  element,
  mode,
  onChange,
  repeaterRunnerInformation,
}) => {
  const { classes } = runnerStyles();
  const theme = useMantineTheme();
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(false);
  
  if (mode === FormElementMode.Viewer) {
    return <ViewerRepeater number={number} element={element} />;
  }

  if (mode === FormElementMode.Editor) {
    return null;
  }

  const renderElement = (
    repeaterElement: FormElement,
    elementIndex: number,
    groupIndex: number,
  ) => {
    const {
      pageIndex,
      elementIndex: pageElementIndex,
      form,
      onFileUpload,
      onFileDelete,
    } = repeaterRunnerInformation as RepeaterRunnerModel;
    const elementPath = repeaterElementPath(pageIndex, pageElementIndex, groupIndex, elementIndex);

    const repeaterElementChange = (value: unknown) => {
      if (repeaterElement.type === FormElementType.FileGroup) {
        if (onFileUpload && value instanceof FileUpload) {
          onFileUpload(value)
            .then((uploadedFile) => {
              form.setFieldValue(elementPath, uploadedFile);
            })
            .catch(() => {
              form.setFieldError(elementPath, ErrorMessages.FILE_UPLOAD_INVALID_FORMAT);
            });
        } else if (onFileDelete && value instanceof FileDelete) {
          onFileDelete(value)
            .then(() => {
              form.setFieldValue(elementPath, null);
              form.clearFieldError(elementPath);
            })
            .catch(() => {
              form.setFieldError(elementPath, ErrorMessages.FILE_UPLOAD_ERROR_DELETING_FILE);
            });
        }
      } else {
        form.setFieldValue(elementPath, value);
      }
    };

    return (
      <FormElementComponent
        element={repeaterElement}
        mode={mode}
        error={form.errors[elementPath]}
        onChange={(value) => repeaterElementChange(value)}
      />
    );
  };

  const addNewRow = () => {
    repeaterRunnerInformation?.form.clearErrors();
    const newArray = JSON.parse(JSON.stringify(element.value)) as FormElement[][];
    const newGroup = JSON.parse(JSON.stringify(element.template)) as FormElement[];

    newGroup.forEach((elementCopy) => {
      elementCopy.id = uuidv4();
    });

    newArray.push(newGroup);

    if (isPresent(onChange)) {
      onChange(newArray);
    }
  };

  useEffect(() => {
    if (element) {
      
      const count = (element.value as FormElement[][])?.length ?? 0;     
      const maxItems = element.maxItems ?? 100;

      if (count >= maxItems) {
        setIsAddButtonDisabled(true);
      } else {
        setIsAddButtonDisabled(false);
      }

      if (element.required && count === 0) {
        addNewRow();        
      } 
    }
  }, [element]);

  const groupCount = (element.value as FormElement[][])?.length ?? 0;
  const descriptionText = LanguageHandler.getTextByLanguage(element.description, LanguageCode.sv);
  const addButtonText = LanguageHandler.getTextByLanguage(element.addButtonText, LanguageCode.sv);

  const getSummary = () => {
    const hasNumberFields = element.template
      .filter((x) => x.type === FormElementType.TypedTextBox)
      .some((x) => (x as FormElementTypedTextBox).inputType === TextBoxInputType.Number);

    if (!hasNumberFields) {
      return null;
    }

    return (
      <Flex direction={'column'} align={'end'} pr={12}>
        {element.template
          .map((el, elementIndex) => {
            if (
              el.type === FormElementType.TypedTextBox &&
              (el as FormElementTypedTextBox).inputType === TextBoxInputType.Number
            ) {
              return (
                <Group key={el.id} w={250} position={'apart'}>
                  <Text size={'sm'} weight={'bold'}>
                    {t`Summa`} {LanguageHandler.getTextByLanguage(el.label, LanguageCode.sv)}
                  </Text>
                  <Text size={'sm'} weight={'bold'}>
                    {Formatter.formatNumber(
                      (element.value as FormElement[][]).reduce(
                        (a, b) => a + Number(b[elementIndex].value),
                        0,
                      ),
                    )}
                  </Text>
                </Group>
              );
            }
          })
          .filter((x) => x)}
      </Flex>
    );
  };

  const showDivider = matches || element.template.reduce((a, b) => a + b.size, 0) > 12;

  return (
    <Input.Wrapper
      label={
        element.hideLabel ? undefined : (
          <FormLabel element={element} mode={mode} position={'left'} />
        )
      }
      description={descriptionText}>
      {groupCount > 0 && (
        <>
          {(element.value as FormElement[][]).map((group, groupIndex) => {
            return (
              <React.Fragment key={`${element.id}_${groupIndex}`}>
                {showDivider && groupIndex > 0 && (
                      <Grid.Col span={12}>
                        <Divider color={theme.colors.gray[5]} variant={'dashed'} />
                      </Grid.Col>
                )}
                <div className={classes.elementsWrapper}>
                  <Grid className={classes.components} p={0}>
                    {group?.map((el, elementIndex) => {
                      el.hideLabel = groupIndex === 0 ? false : element.labelsOnFirstGroup;
                      return (
                        <Grid.Col xs={12} sm={el.size} key={`element_${el.id}`}>
                          <div>{renderElement(el, elementIndex, groupIndex)}</div>
                        </Grid.Col>
                      );
                    })}
                  </Grid>
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
      <Grid>
        <Grid.Col sm={6} orderSm={1} order={2}>
          <Group mt={8}>
            <Button disabled={isAddButtonDisabled} size={'xs'} onClick={() => addNewRow()}>
              {addButtonText}
            </Button>
            {groupCount > (element.required ? 1 : 0) && (
              <Button
                variant={'outline'}
                size={'xs'}
                onClick={async () => {
                  const group = (element.value as FormElement[][])[groupCount - 1];
                  const fileUploadElements = group.filter(
                    (el) => el.type === FormElementType.FileGroup,
                  );

                  if (fileUploadElements.length > 0) {
                    fileUploadElements.forEach(async (fuElment) => {
                      if (isPresent(fuElment.value)) {
                        await repeaterRunnerInformation?.onFileDelete({
                          elementId: fuElment.id,
                          deleteComplete: () => {},
                        });
                      }
                    });
                  }
                  repeaterRunnerInformation?.form.clearErrors();
                  const newArray =
                    JSON.parse(
                      JSON.stringify((element.value as FormElement[][]).slice(0, groupCount - 1)),
                    ) ?? [];
                  if (isPresent(onChange)) {
                    onChange(newArray);
                  }
                }}>
                {t`Ta bort`}
              </Button>
            )}
          </Group>
        </Grid.Col>
        <Grid.Col sm={6} orderSm={2} order={1}>
          {element.sumNumberFields && getSummary()}
        </Grid.Col>
      </Grid>
    </Input.Wrapper>
  );
};
