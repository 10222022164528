import { ApplicationStatus } from './ApplicationStatus';
import { CompletionStatus } from './CompletionStatus';

export interface Application {
  id: string;
  number: string;
  status: ApplicationStatus;
  title?: string;
  type: ApplicationType;
  completionStatus: CompletionStatus;
  applicationPeriodTitle: string;
  modifiedUtc: string;
  hasClaimablePayments: boolean;
  reportingDueDate: string;
  parentApplicationNumber: string;
  parentApplicationTitle: string;
}

export enum ApplicationType {
  Application = 0,
  Report = 1,
}
