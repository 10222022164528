import { Image } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { isMissing } from 'utilitype';
import { useApi } from '../../../../hooks/useApi';
import { UploadedFile } from '../../../../models/UploadedFile';
import { useOrganisationContext } from '../../../../hooks/useOrganisation';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { LiitDrawer } from '../../../LiitDrawer/LiitDrawer';
import { PDFViewer } from '../../../PdfViewer/PDFViewer';
import { toBlob } from '../../../../utils/toBlob';
import { ImageViewer } from '../../../ImageViewer/ImageViewer';
import { LiitActionIcon } from '../../../LiitActionIcon/LiitActionIcon';
import { IconFile } from '@tabler/icons';

const isPdf = (type: string): boolean => type === 'application/pdf';

const PADDING_SPACE = 24;
const DRAWER_WIDTH = 800 + PADDING_SPACE;

export const ViewerFileBox: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const fileElement = element.value as UploadedFile;
  const [thumbnail, setThumbnail] = useState<string>();

  const api = useApi();
  const organisation = useOrganisationContext();
  const [opened, setOpened] = useState(false);
  const PDF_CONTENT_TYPE = 'application/pdf';

  if (isMissing(fileElement)) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        {'Ingen fil uppladdad'}
      </ViewerInputWrapper>
    );
  }

  const { id, type, filename } = fileElement;

  const getThumbnail = async (): Promise<string | null> => {
    if (id && organisation) {
      const arrayBuffer = await api.getImageUrl(
        organisation.id,
        id,
        'thumbnail',
      );

      return arrayBuffer;
    }

    return null;
  };

  useEffect(() => {
    if (!isPdf(type)) {
      getThumbnail()
        .then((data) => {
          if (data) {
            setThumbnail(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const getImageUrl = async (): Promise<string | null> => {
    if (id && organisation) {
      const fileLink = await api.getImageUrl(organisation.id, id, 'compressed');

      return fileLink;
    }
    return null;
  };

  const getData = async (): Promise<string | null> => {
    if (id && organisation) {
      const arrayBuffer = await api.downloadFile(organisation.id, id);

      return URL.createObjectURL(toBlob(new Uint8Array(arrayBuffer), PDF_CONTENT_TYPE));
    }
    return null;
  };

  const getDisplayElement = () => {
    if (isPdf(type)) {
      return (
        <div>
          <LiitActionIcon
            tooltip={filename}
            icon={<IconFile size={48} />}
            size={64}
            variant="subtle"
            onClick={() => setOpened(true)}
          />
        </div>
      );
    } else {
      return (
        <div style={{ width: 120, cursor: 'pointer' }}>
        <Image
        radius={8}
          src={thumbnail}
          w="auto"
          fit="contain"
          onClick={() => setOpened(true)}></Image>
          </div>
      );
    }
  };

  if (id && organisation) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        {/* <Button
          size={'xs'}
          onClick={() => {
            setOpened(true);
          }}>
          Visa
        </Button> */}
        {getDisplayElement()}
        <LiitDrawer
          opened={opened}
          onClose={() => setOpened(false)}
          padding={isPdf(type) ? 0 : 'xl'}
          size={DRAWER_WIDTH}>
          {isPdf(type) && <PDFViewer getData={getData} filename={filename} />}
          {isPdf(type) === false && (
            <ImageViewer getImageUrl={getImageUrl} filename={filename}></ImageViewer>
          )}
        </LiitDrawer>
      </ViewerInputWrapper>
    );
  }

  return <></>;
};
